import React from 'react'
import uniqid from 'uniqid'
import { skills } from '../../portfolio'
import './Skills.css'
import finastra from '../../assets/finastra.png'
import questrade from '../../assets/questrade.png'
import mto from '../../assets/mto.png'
import blackberry from '../../assets/blackberry.png'

const Skills = () => {
  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'>
      <div className='skills__section'>
        <h1 className='about__title'>About Me</h1>

        {/* Opening Statement */}
        <p className='about__intro'>
          {/* As a Management Engineering Student, I have a deep passion for technology and a relentless drive to learn
          new skills and innovate. Whether it&apos;s developing cutting-edge
          data pipelines or exploring the potential of machine learning, I am
          always eager to push the boundaries of what technology can achieve. As seen in my past experiences, I specialize
          in data science and machine learning! */}

          As a Management Engineering student at the University of Waterloo, I am passionate about leveraging technology 
          to drive innovation and solve complex problems. I specialize in data science and machine learning, with hands-on 
          experience in building and optimizing data pipelines, developing ML models, and implementing real-time analytics 
          solutions. I thrive on opportunities to push boundaries and explore the full potential of technology to achieve 
          transformative outcomes. I am excited to bring my unique blend of technical expertise and business acumen to a 
          dynamic team!
        </p>

        {/* Line break after the opening statement */}
        <hr className='about__line' />

        {/* Job Experience Title */}

        {/* Timeline Section */}
        <div className='timeline'>
          <div className='timeline__event'>
            <div className='timeline__icon'>
              <img src={finastra} alt='Profile' />
            </div>
            <h3 className='timeline__title'>
              Technical Project Manager @ Finastra
            </h3>
            <p className='timeline__date'>May 2022 - Aug 2022</p>
            <p className='timeline__description'>
              Managed and led software projects
            </p>
          </div>
          <div className='timeline__event'>
            <div className='timeline__icon'>
              <img src={questrade} alt='Profile' />
            </div>
            <h3 className='timeline__title'>Data Risk Analyst @ Questrade</h3>
            <p className='timeline__date'>Jan 2023 - April 2023</p>
            <p className='timeline__description'>
              Data Governance and Risk Management
            </p>
          </div>
          <div className='timeline__event'>
            <div className='timeline__icon'>
              <img src={mto} alt='Profile' />
            </div>
            <h3 className='timeline__title'>Data Scientist @ MTO</h3>
            <p className='timeline__date'>Sept 2023 - Dec 2023</p>
            <p className='timeline__description'>
              Data pipelines, analytics, and Power BI
            </p>
          </div>
          <div className='timeline__event'>
            <div className='timeline__icon'>
              <img src={blackberry} alt='Profile' />
            </div>
            <h3 className='timeline__title'>Data Engineer @ Blackberry</h3>
            <p className='timeline__date'>May 2024 - Aug 2024</p>
            <p className='timeline__description'>
              LLMs, ML Models, and Data Pipelines
            </p>
          </div>
        </div>

        <h2 className='skills__title'>Skills</h2>
      </div>

      {/* Skills List */}
      <ul className='skills__list'>
        {skills.map((skill) => (
          <li key={uniqid()} className='skills__list-item'>
            <i className={`${skill.class} skills__icon`} />
            {skill.name}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Skills
