// import { useContext, useState } from 'react'
// import { ThemeContext } from '../../contexts/theme'
// import './Navbar.css'
// import resume from '../../assets/kirtiBansal.pdf'

// const Navbar = () => {
//   const [{ themeName }] = useContext(ThemeContext)
//   const [isModalOpen, setModalOpen] = useState(false)

//   const openModal = () => setModalOpen(true)
//   const closeModal = () => setModalOpen(false)

//   return (
//     <nav className='nav'>
//       <ul className='nav__list'>
//         <li className='nav__list-item'>
//           <a href='#skills' className='link link--nav'>
//             About
//           </a>
//         </li>
//         <li className='nav__list-item'>
//           <a href='#projects' className='link link--nav'>
//             Projects
//           </a>
//         </li>
//         <li className='nav__list-item'>
//           <button
//             type='button'
//             onClick={openModal}
//             className='link link--nav nav__button'
//           >
//             Resume
//           </button>
//         </li>
//       </ul>

//       {isModalOpen && (
//         <div className='modal'>
//           <div className='modal-content'>
//             <button
//               type='button'
//               className='close'
//               onClick={closeModal}
//               aria-label='Close Modal'
//             >
//               &times;
//             </button>
//             <iframe src={resume} className='resume-iframe' title='Resume' />
//           </div>
//         </div>
//       )}
//     </nav>
//   )
// }

// export default Navbar

import { useContext, useState } from 'react'
import { ThemeContext } from '../../contexts/theme'
import './Navbar.css'
import resume from '../../assets/kirtiBansal.pdf'

const Navbar = () => {
  const [{ themeName }] = useContext(ThemeContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isNavOpen, setNavOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
  const toggleNav = () => setNavOpen(!isNavOpen)

  return (
    <nav className='nav'>
      <button className='nav__hamburger' onClick={toggleNav} type='button'>
        &#9776;
      </button>

      <ul className={`nav__list ${isNavOpen ? 'show' : ''}`}>
        <li className='nav__list-item'>
          <a
            href='#skills'
            className='link link--nav'
            onClick={() => setNavOpen(false)}
          >
            About
          </a>
        </li>
        <li className='nav__list-item'>
          <a
            href='#projects'
            className='link link--nav'
            onClick={() => setNavOpen(false)}
          >
            Projects
          </a>
        </li>
        <li className='nav__list-item'>
          <button
            type='button'
            onClick={() => {
              openModal()
              setNavOpen(false)
            }}
            className='link link--nav nav__button'
          >
            Resume
          </button>
        </li>
      </ul>

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <button
              type='button'
              className='close'
              onClick={closeModal}
              aria-label='Close Modal'
            >
              &times;
            </button>
            <iframe src={resume} className='resume-iframe' title='Resume' />
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
