import vaccineImage from './assets/Vaccine.png'
import cancerImage from './assets/Cancer.png'
import movieImage from './assets/Movie.png'
import foodImage from './assets/Food.png'
import aiImage from './assets/AI.jpeg'
import meals from './assets/meals.png'

const header = {
  title: 'KB.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Kirti Bansal',
  role: [
    'Data Engineer',
    'Data Scientist',
    'Machine Learning Engineer',
    'Full Stack Developer',
  ],
  social: {
    linkedin: 'https://www.linkedin.com/in/kirti-bansal/',
    github: 'https://github.com/kirtibansal2002',
    email: 'mailto:k8bansal@uwaterloo.ca',
  },
}

const experience = [
  {
    Title: 'Data Engineer',
    Company: 'Blackberry',
    Time: 'May 2024 - August 2024',
  },
  {
    Title: 'Data Scientist',
    Company: 'Ministry of Transportation',
    Time: 'September 2023 - December 2023',
  },
  {
    Title: 'Data Risk Analyst',
    Company: 'Questrade',
    Time: 'January 2023 - April 2023',
  },
  {
    Title: 'Project Manager',
    Company: 'Finastra',
    Time: 'May 2022 - August 2022',
  },
]

const projects = [
  {
    name: 'Covid-19 Vaccine Forecasting',
    image: vaccineImage,
    description:
      'Use the forecasted administered vaccines to create optimal inventory models that determine order quantity and distribution strategy to reduce the wastage of vaccines.',
    stack: [
      'Linear Regression',
      'Holt Method',
      'Winter Method',
      'Simple Exponential Smoothing',
    ],
    sourceCode:
      'https://github.com/kirtibansal2002/COVID-19-Vaccine-Forecasting',
    livePreview:
      'https://docs.google.com/presentation/d/1Df-4Yakm5jxhMIh2TgEv15KtpgMF7kCXiYxsvNPL6BY/edit#slide=id.p',
  },
  {
    name: 'Breast Cancer Detection',
    image: cancerImage,
    description:
      'Assessed the effectiveness of different diagnostic methods for classifying breast cancer as benign or malignant, specifically comparing the performance of ML algorithms using numeric data derived from breast mass cell nucleus data against image classification models using Ultrasound images.',
    stack: ['Logistic Regression', 'Decision Trees', 'KNN', 'CNN', 'SVM'],
    sourceCode:
      'https://github.com/kirtibansal2002/Breast-Cancer-Classification',
    livePreview:
      'https://docs.google.com/presentation/d/1wkXCrhC0sPamxQrkZzmzgkh_qOv4kVuSXNxzhR-xNEk/edit#slide=id.p',
  },
  {
    name: 'M3DI-AI',
    image: aiImage,
    description:
      'Developed a flask web application that records doctor-patient conversations and coverts the transcriptions into structured notes enhancing patient documentation and diagnosis ',
    stack: ['PyTorch', 'Whisper Open AI', 'Hugging Face'],
    sourceCode: 'https://github.com/kirtibansal2002/M3DI_AI',
    // livePreview: 'https://github.com',
  },
  {
    name: 'CineCritique',
    image: movieImage,
    description:
      'A complete web platform designed for movie fans to rate films, view trailers, and conduct in-depth searches using an IMDB-based engine, simplifying the process of discovering and discussing movies',
    stack: ['React', 'JavaScript', 'HTML', 'CSS'],
    sourceCode: 'https://github.com/kirtibansal2002/Cinesift-Movies-Review',
  },
  {
    name: 'Mindful Meals',
    image: meals,
    description:
      'A platform for users to manage food utilization, prevent waste, discover new recipes with available ingredients, and access nutritional information, all while accommodating dietary needs and preferences through personalized accounts and advanced recipe searching features',
    stack: ['JavaScript', 'CSS', 'Jupyter Notebook', 'React', 'MySQL'],
    sourceCode: 'https://github.com/MSci-245-react/Mindful-Meals',
  },
  {
    name: 'Food4Kids',
    image: foodImage,
    description:
      'Built a multi-user app that fairly identifies children suffering from chronic hunger, ensuring that charity funds are directed to those most in need while gathering valuable demographic data and partnering with local organizations for efficient food distribution',
    stack: ['React', 'JavaScript', 'HTML', 'CSS'],
    sourceCode: 'https://github.com/gavneetb/wie-hackathon',
    livePreview: 'https://devpost.com/software/sci-spies',
  },
]
// const skills = [
//   'Python',
//   'MySQL',
//   'PostgreSQL',
//   'Java',
//   'Terraform',
//   'JavaScript',
//   'React',
//   'Node.js',
//   'Pandas',
//   'R',
//   'Git',
// ]

const skills = [
  { name: 'Python', class: 'devicon-python-plain' },
  { name: 'MySQL', class: 'devicon-mysql-plain' },
  { name: 'Databricks', class: 'devicon-databricks-plain' },
  { name: 'PostgreSQL', class: 'devicon-postgresql-plain' },
  { name: 'Java', class: 'devicon-java-plain' },
  { name: 'Terraform', class: 'devicon-terraform-plain' },
  { name: 'Java', class: 'devicon-java-plain' },
  { name: 'JavaScript', class: 'devicon-javascript-plain' },
  { name: 'React', class: 'devicon-react-plain' },
  { name: 'Node.js', class: 'devicon-nodejs-plain' },
  { name: 'Pandas', class: 'devicon-pandas-plain' },
  { name: 'R', class: 'devicon-r-plain' },
  { name: 'Git', class: 'devicon-git-plain' },
  { name: 'AWS', class: 'devicon-amazonwebservices-plain' },
]

const contact = {
  email: 'k8bansal@uwaterloo.ca',
}

export { header, about, projects, skills, contact, experience }
