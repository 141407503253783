import React, { useState } from 'react'
import Typical from 'react-typical'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@material-ui/icons/Email'
import { about } from '../../portfolio'
import './About.css'
import profileImage from '../../assets/kirti.png'
import resume from '../../assets/kirtiBansal.pdf'

const About = () => {
  const { name, role, description, social } = about
  const roleSteps = role ? role.map((r) => [r, 3000]).flat() : []

  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <div className='about'>
      <div className='about__text'>
        {name && (
          <h1>
            Hi, I am <span className='about__name'>{name}.</span>
          </h1>
        )}

        {name && (
          <h2>
            <span className='about__school'>
              Engineering Student @UWaterloo.
            </span>
          </h2>
        )}

        {role && (
          <h2 className='about__role'>
            A <Typical steps={roleSteps} loop={Infinity} wrapper='span' />.
          </h2>
        )}

        <div className='about__contact center'>
          {social && (
            <>
              {social.github && (
                <a
                  href={social.github}
                  aria-label='GitHub'
                  className='link link--icon'
                >
                  <GitHubIcon />
                </a>
              )}

              {social.linkedin && (
                <a
                  href={social.linkedin}
                  aria-label='LinkedIn'
                  className='link link--icon'
                >
                  <LinkedInIcon />
                </a>
              )}

              {social.email && (
                <a
                  href={`mailto:${social.email}`}
                  aria-label='Email'
                  className='link link--icon'
                >
                  <EmailIcon />
                </a>
              )}
            </>
          )}
        </div>
      </div>

      <div className='about__image'>
        <img src={profileImage} alt='Profile' />
      </div>

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <span
              className='close'
              onClick={closeModal}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  closeModal()
                }
              }}
              role='button'
              tabIndex='0'
              aria-label='Close Modal'
            >
              &times;
            </span>
            <iframe src={resume} className='resume-iframe' title='Resume' />
            <div className='modal-footer'>
              {/* <a href={resume} download className='btn btn--download'>
                Download Resume
              </a> */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default About

