import React from 'react'
import './Contact.css'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import EmailIcon from '@material-ui/icons/Email'
import { contact, about } from '../../portfolio'

const Contact = () => {
  if (!contact.email) return null

  const { social } = about 

  return (
    <section className='section contact' id='contact'>

      {social && (
        <div className='contact__icons'>
          {social.github && (
            <a
              href={social.github}
              aria-label='GitHub'
              className='link link--icon'
            >
              <GitHubIcon />
            </a>
          )}

          {social.linkedin && (
            <a
              href={social.linkedin}
              aria-label='LinkedIn'
              className='link link--icon'
            >
              <LinkedInIcon />
            </a>
          )}

          {contact.email && (
            <a
              href={`mailto:${contact.email}`}
              aria-label='Email'
              className='link link--icon'
            >
              <EmailIcon />
            </a>
          )}
        </div>
      )}
    </section>
  )
}

export default Contact
